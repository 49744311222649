.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.header-main {
  font-size: 40px;
  margin-bottom: 50px;
}
.form-container {
  background: #fff;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.form-container h3 {
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  margin-top: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 25px;
  color: #000000;
  margin-bottom: 10px;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group select {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s;
}

.form-group input[type="text"]:focus,
.form-group input[type="number"]:focus,
.form-group select:focus {
  border-color: #007bff;
}

.form-group input[type="radio"] {
  margin-right: 5px;
}

.form-group button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  margin: 20px 0px 0px 0px;
  transition: background-color 0.3s;
}

.form-group button:hover {
  background-color: #0056b3;
}

.form-group button[type="submit"] {
  margin-top: 10px;
}

.form-end {
  text-align: center;
  color: #333;
  font-size: 30px;
  margin: 0
}
