.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
  height: 100vh;
}

.login {
  margin: 150px;
  width: 300px;
  padding: 20px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h1 {
  text-align: center;
  color: #000000;
}
.roles {
}
form {
  display: flex;
  width: 100%;
  flex-direction: column;
}

label {
  margin-bottom: 18px;
  color: #000000;
}
.img {
  height: 150px;
  width: 150px;
  margin-bottom: 5px;
  margin-top: 10px;
}
input,
button {
  padding: 12px;
  margin: 10px;
  border-radius: 4px;
}
.error {
  margin: 0px;
  text-align: center;
  color: red;
}
.button {
  padding: 10px;
  background-color: #135e91;
  color: #ffffff;
  border: none;
  font-weight: 500;
  font-size: 18px;
  width: 220px;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #06375f;
}
.bttn {
  display: flex;
  justify-content: center;
}
.eye-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 2px;
  cursor: pointer;
}
.lid {
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: #000;
  transform-origin: 50% 0;
  transition: transform 0.3s ease;
}

.eye-icon.open .lid {
  transform: rotateX(0deg);
}

.eye-icon .lid {
  transform: rotateX(60deg);
}
