.form-stats-container {
  width: 80%;
  margin: 20px auto;
}

.form-stats-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Hide the name cells except the first occurrence */
.hidden-name {
  visibility: hidden;
}

/* Apply this style to the first occurrence to show it fully */
.first-occurrence {
  visibility: visible;
}

.form-stats-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.form-stats-table th, .form-stats-table td {
  padding: 12px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}

.form-stats-table th {
  background-color: #f4f4f4;
}

.form-stats-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.form-stats-total td {
  font-weight: bold;
  text-align: center;
}

.form-stats-message {
  text-align: center;
  font-size: 1.2rem;
}
