.main-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F9F7F7;
  text-align: center;
  padding: 10px;
  margin-top: 1%;
}
.head-text-welcome{
  font-size: 26px;
  margin-bottom: 0px;
}
.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header {
  font-size: 2.5em;
  color: #333;
  margin: 10px 0;
}

.paragraph {
  font-size: 2em;
  color: #3585e0;
  font-weight: 500;
  margin: 10px 0;
  margin-top: 1.5%;
}

.buttons, .buttons2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2%;
}

.menu-buttons {
  text-decoration: none;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  align-items: center;
  transition: color 0.3s, background-color 0.3s;
}

.menu-buttons:hover {
  color: #3F72AF;
  background-color: #F9F7F7;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .header {
    font-size: 2em;
  }
  .paragraph {
    font-size: 1.5em;
  }
  .menu-buttons {
    font-size: 18px;
    padding: 8px;
    margin: 8px;
  }
}

@media (max-width: 480px) {
  .header {
    font-size: 1.5em;
  }
  .paragraph {
    font-size: 1.2em;
  }
  .menu-buttons {
    font-size: 16px;
    padding: 6px;
    margin: 6px;
  }
}
