.data-display-container {
    width: 95%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .data-table thead {
    background-color: #007bff;
    color: white;
  }
  
  .data-table th {
    padding: 12px 15px;
    text-align: center;
    border: 1px solid #ddd;
  }
  .data-table td {
    padding: 12px 15px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .data-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .data-table tbody tr:hover {
    background-color: #e9e9e9;
  }
  
  .confirm-button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
  }
  
  .confirm-button:hover {
    background-color: #218838;
  }
  